import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  placeholder: {
    height: 70,
    [theme.breakpoints.up("sm")]: {
      height: 81,
    },
  },
  appbar: {
    background: "#28282a",
    height: 70,
    zIndex: "5",
    [theme.breakpoints.up("sm")]: {
      height: 81,
      zIndex: "3",
    },
  },
  toolbar: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    justifyContent: "space-between",
    flex: 'auto',
  },
  navegation: {
    "& > *": {
      color: "white",
      fontFamily: "Century-Gothic",
      [theme.breakpoints.down(1020)]: {
        fontSize: "smaller",
      },
    },
    "& > * + *": {
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down("md")]: {
        marginLeft: theme.spacing(1.5),
      },
    },
  },
  navegationSelect: {
    color: "#86bbd8",
  },
  loginButton: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        marginLeft: theme.spacing(1),
      },
    },
  },
  bottomPrimary: {
    paddingBlockStart: "10px",
    paddingBlockEnd: "10px",
    fontWeight: "bold",
    background: "#33658A",
    color: "white",
    fontFamily: "Century-Gothic",
    "&:hover": {
      backgroundColor: "#33658A",
    },
    [theme.breakpoints.down("md")]: {
      paddingInlineStart: "10px",
      paddingInlineEnd: "10px",
      fontSize: 12,
    },
    [theme.breakpoints.up("1100")]: {
      paddingInlineStart: "14px",
      paddingInlineEnd: "14px",
      fontSize: 15,
    },
  },
  bottomSecondary: {
    paddingBlockStart: "10px",
    paddingBlockEnd: "10px",
    fontWeight: "bold",
    background: "white",
    color: "#33658A",
    fontFamily: "Century-Gothic",
    "&:hover": {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("md")]: {
      paddingInlineStart: "10px",
      paddingInlineEnd: "10px",
      fontSize: 12,
    },
    [theme.breakpoints.up("1100")]: {
      paddingInlineStart: "14px",
      paddingInlineEnd: "14px",
      fontSize: 15,
    },
  },
  iconMenu: {
    color: "white",
    fontSize: 35,
    [theme.breakpoints.down("xs")]: {
      fontSize: 25,
    }
  },
  menuButton: {
    marginRight: theme.spacing(1), 
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(0),
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerIconButton: {
    color: "white",
    fontSize: 80,
  },
  listIconButton: {
    color: "white",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "end",
    height: 70,
    [theme.breakpoints.up("sm")]: {
      height: 81,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#28282a",
    color: "white",
  },
  listItext: {
    marginLeft: theme.spacing(2),
  },
  logocidu: {
    //pointerEvents: 'none',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0),
    },
  },
  logociduGrande: {
    //marginLeft: theme.spacing(2),
    //pointerEvents: 'none',
    height: 'auto',
    width: 170,
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(0),
      width: 150,
    },
  },
}));

export default useStyles;
