import React, { useState } from 'react';
import Router, { useRouter } from 'next/router';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import StorageIcon from '@material-ui/icons/Storage';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MailIcon from '@material-ui/icons/Mail';
import WebIcon from '@material-ui/icons/Web';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import StoreIcon from '@material-ui/icons/Store';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';   
//STYLES
import useStyles from './style.js'; 

export default function AppBarCidu() { 
  const { route } = useRouter();
  const classes = useStyles(); 
  const [stateOpen, setOpen] = useState(false);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  let isNosotros = null
  let isServicios = null
  let isDirector = null
  let isClientes = null
  let isPlanes = null
  let isContactenos = null

  switch (route) {
    case '/nosotros':
      isNosotros = true
      break
    case '/servicios':
      isServicios = true
      break
    case '/director':
      isDirector = true
      break
    case '/clientes':
      isClientes = true
      break
    case '/planes':
      isPlanes = true
      break
    case '/contacto':
      isContactenos = true
      break
    default:
      break
  }

  function gotoPage(e) {
    let screen = e;
    Router.push(screen);
  }

  const gotoLink = (e) => {
    window.open(e, "_blank", 'noopener,noreferrer');
  }

  const other = (e, screen) => {
    e.preventDefault();
    Router.push(screen);
  }

  const gotoNavegation = (e) => {
    e.preventDefault();
    let screen = e.target.id;
    Router.push(screen);
  }

  return (
    <div className={classes.position} >
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>

          <Hidden smDown>
            <Grid item xs={12} container direction="row" alignItems="center" justify="space-between"> 
 
                  <a onClick={(e) => other(e, "/")} href="">
                    <img src={"/images/icons/LogoNombre.svg"} 
                      alt="cidu" 
                      width={100} 
                      height={'auto'} 
                      className={classes.logociduGrande} 
                      title='CIDU' 
                      draggable="false"/> 
                  </a> 
 
                <Grid item xs={6} container direction="row" alignItems="center" justify="center">  
                      <Typography variant="h5" className={classes.navegation}>
                        <a onClick={gotoNavegation} className={isNosotros && classes.navegationSelect} href="" id="/nosotros">Nosotros</a>
                        <a onClick={gotoNavegation} className={isServicios && classes.navegationSelect} href="" id="/servicios">Servicios</a>
                        <a onClick={gotoNavegation} className={isDirector && classes.navegationSelect} href="" id="/director">Director</a>
                        <a onClick={gotoNavegation} className={isClientes && classes.navegationSelect} href="" id="/clientes">Clientes</a>
                        <a onClick={gotoNavegation} className={isPlanes && classes.navegationSelect} href="" id="/planes">Planes</a>
                        <a onClick={gotoNavegation} className={isContactenos && classes.navegationSelect} href="" id="/contacto">Contáctenos</a>
                        <a href="https://blog.cidu.com.co" target="_blank" rel="noopener noreferrer">Blog</a>
                      </Typography> 
                </Grid> 

              
                <Grid item className={classes.loginButton}>
                  <Button
                    className={classes.bottomSecondary}
                    size="large"
                    onClick={() => gotoLink("https://app.cidu.com.co")}>Ingresar</Button> 
                </Grid>  
 
            </Grid>
          </Hidden>

          <Hidden mdUp>
            <Grid item xs={12} container direction="row" alignItems="center" justify="space-between"> 

                <Grid item>
                  <a onClick={(e) => other(e, "/")} href=""> 
                    <img 
                      src={"/images/icons/LogoNombre.svg"} 
                      className={classes.logocidu} 
                      draggable="false"
                      alt="cidu"
                      width={140} height={140} 
                      title={'CIDU'}/>
                  </a>
                </Grid>

                <Grid item>
                  <IconButton 
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={classes.menuButton}>
                    <MenuIcon  className={classes.iconMenu}/>
                  </IconButton>

                  <Drawer 
                    open={stateOpen}
                    className={classes.drawer}
                    anchor='right'
                    classes={{paper: classes.drawerPaper}}>
         
                    <Grid className={classes.drawerHeader}>
                      <IconButton 
                        className={classes.drawerIconButton}
                        onClick={handleDrawerClose}>
                        { stateOpen ? <ChevronLeftIcon className={classes.iconMenu}/> : <ChevronRightIcon className={classes.iconMenu}/>}
                      </IconButton>
                    </Grid>

                    <Divider />
                    <List>
                      <ListItem button key="Nosotros" onClick={() => gotoPage("/nosotros")}>
                        <ListItemText primary="Nosotros" className={classes.listItext}/>
                        <ListItemSecondaryAction>  <PeopleAltIcon  className={classes.listIconButton}/> </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem button key="Servicios" onClick={() => gotoPage("/servicios")}>
                        <ListItemText primary="Servicios" className={classes.listItext}/>
                        <ListItemSecondaryAction> <StorageIcon  className={classes.listIconButton}/> </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem button key="Director" onClick={() => gotoPage("/director")}>
                        <ListItemText primary="Director" className={classes.listItext}/>
                        <ListItemSecondaryAction> <StoreIcon  className={classes.listIconButton}/> </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem button key="Clientes" onClick={() => gotoPage("/clientes")}>
                        <ListItemText primary="Clientes" className={classes.listItext}/>
                        <ListItemSecondaryAction>  <AssignmentIndIcon  className={classes.listIconButton}/> </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem button key="Planes" onClick={() => gotoPage("/planes")}>
                        <ListItemText primary="Planes" className={classes.listItext}/>
                        <ListItemSecondaryAction>  <AddShoppingCartIcon  className={classes.listIconButton}/> </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem button key="Contactenos" onClick={() => gotoPage("/contacto")}>
                        <ListItemText primary="Contactenos" className={classes.listItext}/>
                        <ListItemSecondaryAction>  <MailIcon  className={classes.listIconButton}/> </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem button key="Blog" onClick={() => gotoLink("https://blog.cidu.com.co")}>
                        <ListItemText primary="Blog" className={classes.listItext}/>
                        <ListItemSecondaryAction>  <WebIcon  className={classes.listIconButton}/> </ListItemSecondaryAction>
                      </ListItem>  
                    </List>
                    <Divider /> 
                    <List>
                      <ListItem button key="Ingresar" onClick={() => gotoLink("https://app.cidu.com.co")}>
                        <ListItemText primary="Ingresar" className={classes.listItext}/>
                        <ListItemSecondaryAction>  <ExitToAppIcon  className={classes.listIconButton}/> </ListItemSecondaryAction>
                      </ListItem> 
                    </List> 

                  </Drawer>
                </Grid>
              </Grid> 
          </Hidden>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
}
